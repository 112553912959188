<template>
  <b-container>
    <b-row align-h="center">
      <b-col md="8">
        <b-card>
          <b-badge :variant="statusVariant(this.status)" class="mb-1">
            <span v-if="this.status === 1">Menunggu Respon</span>
            <span v-else-if="this.status === 2">Dalam Proses</span>
            <span v-else-if="this.status === 3">Ditolak</span>
            <span v-else-if="this.status === 4">Selesai</span>
          </b-badge>
          <b-form v-on:submit.prevent>
            <b-row>
              <b-col md="8">
                <b-form-group label="Nama Pelanggan" label-for="name">
                  <b-form-input id="name" v-model="form.name" readonly />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Tanggal Permintaan" label-for="date">
                  <b-form-input
                    id="name"
                    v-model="form.date"
                    readonly
                  /> </b-form-group
              ></b-col>
            </b-row>
            <b-form-group label="Deskripsi" label-for="description">
              <b-form-textarea
                id="description"
                placeholder="Deskripsi"
                v-model="form.description"
                readonly
              />
            </b-form-group>
            <b-form-group label="Lokasi" label-for="location">
              <b-form-textarea
                id="location"
                placeholder="Lokasi"
                v-model="form.location"
                :disabled="this.status !== 1 ? true : false"
                readonly
              />
            </b-form-group>
            <b-row>
              <b-col md="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-success"
                  block
                  class="my-1"
                  @click="toWhatsApp()"
                >
                  Hubungi Pelanggan
                </b-button>
              </b-col>
              <b-col md="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  v-b-modal.modal-photo
                  class="my-1"
                >
                  Lihat Foto
                </b-button>
                <b-modal
                  id="modal-photo"
                  ok-only
                  ok-title="close"
                  centered
                  size="lg"
                  title="Photo"
                  class="text-center"
                >
                  <b-img
                    id="photo"
                    :src="photo"
                    width="1000px"
                    fluid
                    alt="trouble photo"
                  />
                </b-modal>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card v-if="status === 3">
          <b-form-group label="Reject Reason" label-for="reject_reason">
            <b-form-textarea
              id="reject_reason"
              placeholder="Reject Reason"
              v-model="form.reject_reason"
              disabled
            />
          </b-form-group>
        </b-card>
        <b-row v-if="status === 1">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="danger"
              class="mr-1"
              block
              v-b-modal.modal-reject
            >
              Tolak
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              block
              @click="process()"
            >
              Proses
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="status === 2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              block
              @click="finish()"
            >
              Selesai
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4" v-if="form.unit_id != null">
        <b-card>
          <b-row>
            <b-col md="12" class="mb-1">
              <label for="details" class="text-primary font-weight-bold"
                >Detail Unit</label
              >
            </b-col>
            <b-col md="12" class="mb-2">
              <b-img
                id="image"
                :src="unit.image"
                fluid
                alt="Unit Image"
                v-b-modal.modal-lg
              />
              <b-modal
                id="modal-lg"
                ok-only
                ok-title="close"
                centered
                size="lg"
                title="Large Modal"
                class="text-center"
              >
                <b-img
                  id="image"
                  :src="unit.image"
                  width="1000px"
                  fluid
                  alt="Unit image"
                />
              </b-modal>
            </b-col>
            <b-col md="12">
              <b-row>
                <b-col md="4"> Nopol </b-col>
                <b-col md="8" class="text-right font-weight-bolder">
                  {{ unit.police_no }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col md="4"> Nama </b-col>
                <b-col md="8" class="text-right font-weight-bolder">
                  {{ unit.name }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col md="6"> Kilometer terakhir </b-col>
                <b-col md="6" class="text-right font-weight-bolder">
                  {{ formatNumber(unit.mileage) }} km
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col>
                  <router-link :to="'/unit/' + unit.id">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      block
                      class="my-1"
                    >
                      Lihat Detail
                    </b-button>
                  </router-link>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <!-- modal change password -->
      <b-modal
        id="modal-reject"
        title="Penolakan Permintaan"
        ok-title="Kirim"
        cancel-variant="outline-secondary"
        centered
        hide-footer
      >
        <validation-observer ref="loginValidation">
          <b-form ref="form" @submit.prevent>
            <b-form-group
              :state="errors"
              label="Penolakan Permintaan"
              label-for="reject"
              invalid-feedback="Penolakan permintaan tidak boleh kosong"
            >
              <validation-provider
                #default="{ errors }"
                name="reject"
                rules="required"
              >
                <b-form-textarea
                  id="reject"
                  name="reject"
                  type="text"
                  v-model="form.reject_reason"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Penolakan Permintaan"
                  required="required"
                />
                <small class="text-danger">{{ errors[0] || errMessage }}</small>
              </validation-provider>
            </b-form-group>

            <b-row>
              <b-col>
                <b-button
                  class="mt-1 mb-2"
                  type="submit"
                  variant="danger"
                  block
                  @click="reject()"
                >
                  Kirim
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- end modal change password -->
    </b-row>
  </b-container>
</template>

<script>
import {
  BFormTextarea,
  BBadge,
  BImg,
  BFormTimepicker,
  BFormDatepicker,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import flatPickr from "vue-flatpickr-component";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import moment from "moment";

export default {
  components: {
    ToastificationContent,
    flatPickr,
    Cleave,
    BFormTextarea,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    BImg,
    BFormTimepicker,
    BFormDatepicker,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    draggable,
    Prism,
    BContainer,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        reject_reason: "",
        location: "",
        latitude: "",
        longitude: "",
        phone: "",
        unit_id: null,
      },
      unit: {
        image: "",
        name: "",
        police_no: "",
        mileage: "",
      },
      status: "",
      photo: "",
      permissions: [],
      errors: "",
      errMessage: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        1: "light-warning",
        2: "light-primary",
        3: "light-danger",
        4: "light-success",
      };

      return (status) => statusColor[status];
    },
  },
  setup() {
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  created() {
    this.getAllPermissions();
    this.getDetails();
  },
  methods: {
    moment(date) {
      return moment(date).format("DD-MM-YYYY, hh:mm");
    },
    formatNumber(value) {
      return new Intl.NumberFormat("id-ID", {
        style: "decimal",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(value);
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getDetails() {
      const id = this.$route.params.id;
      const { baseUrl } = $themeConfig.app;
      const imageUri = baseUrl + "image/request/unit-replacement/";

      this.$http
        .get("request/unit-replacement/" + id)
        .then((response) => {
          this.status = response.data.data.status;
          this.form.name = response.data.data.customer.name;
          this.form.location = response.data.data.location;
          this.form.latitude = response.data.data.latitude;
          this.form.longitude = response.data.data.longitude;
          this.form.date = this.moment(response.data.data.created_at);
          this.form.description = response.data.data.description;
          this.form.reject_reason = response.data.data.reject_reason;
          this.form.phone = response.data.data.customer.phone;
          this.form.unit_id = response.data.data.unit_id;
          this.photo = imageUri + response.data.data.image;

          let $unit = response.data.data.unit;
          this.unit.image = baseUrl + "image/unit/" + $unit.image;
          this.unit.name = $unit.name;
          this.unit.mileage = $unit.mileage;
          this.unit.police_no = $unit.police_no;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    process() {
      const id = this.$route.params.id;
      this.$http
        .post("request/unit-replacement/" + id + "/process")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan penggantian unit diproses",
              variant: "success",
            },
          });
          location.href = "/request/unit-replacements";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    finish() {
      const id = this.$route.params.id;
      this.$http
        .post("request/unit-replacement/" + id + "/finish")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan penggantian unit selesai",
              variant: "success",
            },
          });
          location.href = "/request/unit-replacements";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    reject() {
      const id = this.$route.params.id;
      this.$http
        .post("request/unit-replacement/" + id + "/reject", {
          reject_reason: this.form.reject_reason,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan penggantian unit ditolak",
              variant: "danger",
            },
          });
          location.href = "/request/unit-replacements";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    toWhatsApp() {
      const phone = this.form.phone;
      var uri = "https://wa.me/62" + phone.substring(1);
      window.open(uri, "_blank", "noreferrer");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>